import { HttpErrorResponse } from '@angular/common/http';

import { createSingleEntityStore, ngrxEvent } from '@recruitee/ngrx';

import { EntityStoreKey, ForgotPasswordPayload, ResetPasswordPayload } from '../types';

const { actions, reducer, namespace } = createSingleEntityStore<any>()(EntityStoreKey.referrer, {
  customActions: adapter => ({
    forgotPassword: ngrxEvent<ForgotPasswordPayload>(),
    forgotPasswordSuccess: ngrxEvent<void>(),
    forgotPasswordFail: ngrxEvent<HttpErrorResponse>(),
    resetPassword: ngrxEvent<ResetPasswordPayload>(),
    resetPasswordSuccess: ngrxEvent<void>(),
    resetPasswordFail: ngrxEvent<HttpErrorResponse>(),
  }),
});

export {
  actions as ReferrerEntityActions,
  reducer as referrerReducer,
  namespace as ReferrerNamespace,
};
