export enum EntityStoreKey {
  referrer = 'referrer',
}

export type ResetPasswordPayload = {
  token: string;
  emailCode: string;
  password: string;
  passwordConfirmation: string;
};

export type ForgotPasswordPayload = {
  email: string;
};
