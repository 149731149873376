import { TutorialDialogAsset } from '@recruitee/product-updates';

const assets: Record<TutorialDialogAsset, () => Promise<{ default: string }>> = {
  'multilang-intro/multilang-intro-step-1.png': () =>
    import(
      'libs/feature-discovery/product-updates/src/lib/wrappers/tutorial-dialog/assets/multilang-intro/multilang-intro-step-1.png'
    ),
  'multilang-intro/multilang-intro-step-1_2x.png': () =>
    import(
      'libs/feature-discovery/product-updates/src/lib/wrappers/tutorial-dialog/assets/multilang-intro/multilang-intro-step-1_2x.png'
    ),
  'multilang-intro/multilang-intro-step-2.png': () =>
    import(
      'libs/feature-discovery/product-updates/src/lib/wrappers/tutorial-dialog/assets/multilang-intro/multilang-intro-step-2.png'
    ),
  'multilang-intro/multilang-intro-step-2_2x.png': () =>
    import(
      'libs/feature-discovery/product-updates/src/lib/wrappers/tutorial-dialog/assets/multilang-intro/multilang-intro-step-2_2x.png'
    ),
  'multilang-intro/multilang-intro-step-3.png': () =>
    import(
      'libs/feature-discovery/product-updates/src/lib/wrappers/tutorial-dialog/assets/multilang-intro/multilang-intro-step-3.png'
    ),
  'multilang-intro/multilang-intro-step-3_2x.png': () =>
    import(
      'libs/feature-discovery/product-updates/src/lib/wrappers/tutorial-dialog/assets/multilang-intro/multilang-intro-step-3_2x.png'
    ),
  'referrals-onboarding/referrals-onboarding-step-0.svg': () =>
    import(
      'libs/feature-discovery/product-updates/src/lib/wrappers/tutorial-dialog/assets/referrals-onboarding/referrals-onboarding-step-0.svg'
    ),
  'referrals-onboarding/referrals-onboarding-step-2.svg': () =>
    import(
      'libs/feature-discovery/product-updates/src/lib/wrappers/tutorial-dialog/assets/referrals-onboarding/referrals-onboarding-step-2.svg'
    ),
  'referrals-onboarding/referrals-onboarding-step-3.svg': () =>
    import(
      'libs/feature-discovery/product-updates/src/lib/wrappers/tutorial-dialog/assets/referrals-onboarding/referrals-onboarding-step-3.svg'
    ),
  'referrals-onboarding/referrals-onboarding-step-4.svg': () =>
    import(
      'libs/feature-discovery/product-updates/src/lib/wrappers/tutorial-dialog/assets/referrals-onboarding/referrals-onboarding-step-4.svg'
    ),
  'requisitions-intro/requisitions-intro-step-1.svg': () =>
    import(
      'libs/feature-discovery/product-updates/src/lib/wrappers/tutorial-dialog/assets/requisitions-intro/requisitions-intro-step-1.svg'
    ),
  'requisitions-intro/requisitions-intro-step-2.svg': () =>
    import(
      'libs/feature-discovery/product-updates/src/lib/wrappers/tutorial-dialog/assets/requisitions-intro/requisitions-intro-step-2.svg'
    ),
  'requisitions-intro/requisitions-intro-step-3.svg': () =>
    import(
      'libs/feature-discovery/product-updates/src/lib/wrappers/tutorial-dialog/assets/requisitions-intro/requisitions-intro-step-3.svg'
    ),
  'requisitions-intro/requisitions-intro-step-4.svg': () =>
    import(
      'libs/feature-discovery/product-updates/src/lib/wrappers/tutorial-dialog/assets/requisitions-intro/requisitions-intro-step-4.svg'
    ),
  'requisitions-job/requisitions-job-step-1.svg': () =>
    import(
      'libs/feature-discovery/product-updates/src/lib/wrappers/tutorial-dialog/assets/requisitions-job/requisitions-job-step-1.svg'
    ),
  'requisitions-job/requisitions-job-step-2.svg': () =>
    import(
      'libs/feature-discovery/product-updates/src/lib/wrappers/tutorial-dialog/assets/requisitions-job/requisitions-job-step-2.svg'
    ),
};

export const resolveTutorialDialogAsset = (file: TutorialDialogAsset): Promise<string> =>
  assets[file]().then(module => module.default);
