import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { RtAnalyticsModule } from '@recruitee/analytics';
import { resolveTutorialDialogAsset } from '@recruitee/assets/tutorial-dialog';
import { TutorialDialogModule } from '@recruitee/product-updates';

import { SharedModule } from '../../shared/shared.module';
import { FooterComponent } from './footer/footer.component';
import { HeaderLogoComponent } from './header/header-logo/header-logo.component';
import { HeaderComponent } from './header/header.component';
import { SignInButtonComponent } from './header/sign-in-button/sign-in-button.component';
import { SignUpButtonComponent } from './header/sign-up-button/sign-up-button.component';
import { MainViewComponent } from './main-view.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { TermsDialogComponent } from './terms-dialog/terms-dialog.component';

const DIALOGS = [TermsDialogComponent];

const COMPONENTS = [
  MainViewComponent,
  HeaderLogoComponent,
  SignUpButtonComponent,
  SignInButtonComponent,
  FooterComponent,
  HeaderComponent,
  SidebarComponent,
  ...DIALOGS,
];

const TUTORIAL_DIALOG_TRANSLATIONS = {
  dialogBody: {
    learnMore: 'rtr.common.learn_more',
    skip: 'rtr.tutorial_dialog.skip_tour',
    startTour: 'rtr.tutorial_dialog.start_tour',
    continue: 'rtr.common.continue',
    finish: 'rtr.common.finish',
  },
  tutorials: {
    referrals_onboarding: {
      step_0: {
        description: 'rtr.tutorial_dialog.tutorials.referrals_onboarding.step_0.description',
        title: 'rtr.tutorial_dialog.tutorials.referrals_onboarding.step_0.title',
      },
      step_2: {
        description: 'rtr.tutorial_dialog.tutorials.referrals_onboarding.step_2.alt_description',
        title: 'rtr.tutorial_dialog.tutorials.referrals_onboarding.step_2.title',
      },
      step_3: {
        description: 'rtr.tutorial_dialog.tutorials.referrals_onboarding.step_3.alt_description',
        title: 'rtr.tutorial_dialog.tutorials.referrals_onboarding.step_3.alt_title',
      },
      step_4: {
        description: 'rtr.tutorial_dialog.tutorials.referrals_onboarding.step_4.alt_description',
        title: 'rtr.tutorial_dialog.tutorials.referrals_onboarding.step_4.alt_title',
      },
    },
  },
};

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    RtAnalyticsModule,
    SharedModule,
    TutorialDialogModule.withProviders(TUTORIAL_DIALOG_TRANSLATIONS, resolveTutorialDialogAsset),
    ...COMPONENTS,
  ],
  exports: [...COMPONENTS],
})
export class MainViewModule {}
